<template>
  <div class="ActivityAdd">
    <div class="tips">基础信息</div>
    <el-form ref="baseForm" :rules="rules" class="mt20" :model="baseInfo" label-width="130px">
      <el-form-item label="活动名称：" prop="actName">
        <el-input placeholder="请输入活动名称 " v-model="baseInfo.actName" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="活动时间：" prop="timeRange">
        <el-date-picker
          v-model="baseInfo.timeRange"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptionsStart"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="活动区域：" prop="areaList" class="province">
        <ul class="fee-ul">
          <li class="f v" v-for="(item,ii) in baseInfo.areaList" :key="ii" style="margin:10px 0 0;">
            <div class="f fvc">
              <v-distpicker
                :province="item.province"
                :city="item.city"
                @province="(data) => onChangeProvince(data,ii)"
                @city="(data) => onChangeCity(data,ii)"
                :placeholders="{ province: '全部', city: '全部' }"
                hide-area
              ></v-distpicker>
              <i class="el-icon-circle-close" @click="delStoreFee(item,ii)"></i>
            </div>
          </li>
        </ul>
        <!-- <v-distpicker @selected="onSelected" @changeprovince="changeLocation"></v-distpicker> -->
        <div class="form_tips">
          （不在活动区域
          <span class="color_E6A23C">[省市]</span> 的用户，无法参与活动，请谨慎设置）
        </div>
        <el-button @click="addStoreFee" type="primary">添 加 活 动 区 域</el-button>
      </el-form-item>
      <el-form-item label="活动海报图：" prop="poster" class="up_poster">
        <label for="brand-logo" class="el-upload el-upload--picture-card">
          <input
            type="file"
            ref="brandlogo"
            hidden
            id="brand-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage"
          />
          <img v-if="baseInfo.poster" :src="baseInfo.poster" style="width:142px;height:142px;" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </label>
        <span class="ml10 form_tips">建议尺寸750x876 px，支持JPG，PNG，JPEG，GIF格式，限制大小4M内</span>
      </el-form-item>
      <el-form-item label="投诉电话：">
        <el-input
          placeholder="请输入投诉电话"
          v-model="baseInfo.complainMobile"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="直播开始时间：">
        <el-date-picker
          v-model="baseInfo.liveStartTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="开播时间"
          :picker-options="pickerOptionsStart"
        ></el-date-picker>
        <div class="form_tips">（到达直播开始时间后，活动页【预约直播】入口将变更为【观看直播】）</div>
      </el-form-item>
      <el-form-item label="直播地址：">
        <el-input placeholder="请填写直播页URL" v-model="baseInfo.liveUrl"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="submitInfo" type="primary">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";

import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

export default {
  name: "ActivityAdd", // 添加活动
  components: {
    VDistpicker,
  },
  data() {
    return {
      upload: null, // 上传对象
      baseInfo: {
        actName: "", // 活动名称
        timeRange: [],
        actStartTime: "", // 活动开始时间
        actEndTime: "", // 活动结束时间
        areaList: [
          {
            city: "",
            province: "",
          },
        ], // 活动区域列表
        poster: "", // 活动海报图url
        complainMobile: "", // 投诉电话
        liveStartTime: "", // 直播开始时间
        liveUrl: "", // 直播地址
      },
      rules: {
        actName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        poster: [
          { required: true, message: "请添加活动海报图", trigger: "blur" },
        ],
        timeRange: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
        areaList: [
          { required: true, message: "请选择完整的活动区域", trigger: "blur" },
        ],
      },
      pickerOptionsStart: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
    };
  },
  methods: {
    checkHTTP(val) {
      return /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(
        val
      );
    },
    submitInfo() {
      // this.$router.push({ path: `/activity/baseInfo/${57}` });
      // return
      this.$refs.baseForm.validate(async (valid) => {
        try {
          if (valid) {
            let act = JSON.parse(JSON.stringify(this.baseInfo));
            var confirmok = true;
            var length = this.baseInfo.areaList.length;
            this.baseInfo.areaList.forEach((item) => {
              if (!item.provinceId && length > 1) {
                confirmok = false;
              }
            });
            if (!confirmok) {
              this.$message.warning("请选择活动地区");
              return;
            }
            if (act.liveUrl && this.checkHTTP(act.liveUrl) == false) {
              this.$message.warning("请填写正确的直播页URL");
              return;
            }
            act.actStartTime = act.timeRange[0];
            act.actEndTime = act.timeRange[1];
            delete act.timeRange;
            const { data } = await ActivityAjax.postActivitySave(act);
            this.$message.success("保存成功");
            localStorage.setItem("pcActiveName", this.baseInfo.actName);
            this.$router.push({ path: `/activity/baseInfo/${data}` });
          } else {
            return false;
          }
        } catch (error) {
          this.$message.error(error);
        }
      });
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.baseInfo.poster = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    onSelected(data, index) {
      this.baseInfo.areaList[index] = {
        province: data.province.value,
        provinceId: data.province.code,
        city: data.city.value,
        cityId: data.city.code,
        county: data.area.value,
        countyId: data.area.code,
      };
    },
    onChangeProvince(data, index) {
      this.baseInfo.areaList[index].province = data.value;
      this.baseInfo.areaList[index].provinceId = data.code;
      this.baseInfo.areaList[index].city = "全部";
      this.baseInfo.areaList[index].cityId = null;
      if (data.value == "全部") {
        this.baseInfo.areaList[index].province = "全部";
        this.baseInfo.areaList[index].provinceId = null;
        this.baseInfo.areaList[index].city = "全部";
        this.baseInfo.areaList[index].cityId = null;
      }
    },
    onChangeCity(data, index) {
      this.baseInfo.areaList[index].city = data.value;
      this.baseInfo.areaList[index].cityId = data.code;
      if (data.value == "全部") {
        this.baseInfo.areaList[index].city = "全部";
        this.baseInfo.areaList[index].cityId = null;
      }
    },
    // onChangeArea(data, index) {
    //   this.baseInfo.areaList[index].county = data.value;
    //   this.baseInfo.areaList[index].countyId = data.code;
    //   if (data.value == "区") {
    //     this.baseInfo.areaList[index].county = null;
    //     this.baseInfo.areaList[index].countyId = null;
    //   }
    // },
    changeLocation(data) {
      console.log(data);
      // if (data.province.value == "全部") {
      //   data.province.value = "";
      // }
      // this.indexinfo.province = data.province.value;
      // this.indexinfo.citys = data.province.value;
      // if (data.city.value == "全部") {
      //   this.indexinfo.city = "";
      // }
    },
    addStoreFee() {
      var self = this;
      var item = {
        city: "",
        province: "",
      };
      if (this.baseInfo.areaList.length > 10) {
        this.$message.warning("最多选择10个区域");
      } else {
        this.baseInfo.areaList.push(item);
      }
    },
    delStoreFee(item, index) {
      this.baseInfo.areaList.splice(index, 1);
    },
  },
  created() {
    this.$bus.$emit("breadcrumbItem", [{ name: "活动" }, { name: "添加活动" }]);
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>

<style lang="scss">
.ActivityAdd {
  .fee-ul .el-icon-circle-close {
    font-size: 20px;
    margin: 0 0 0 10px;
    color: #f56c6c;
    cursor: pointer;
  }

  .fee-ul li:nth-child(1) .el-icon-circle-close {
    display: none;
  }
  .tips {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 10px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 2px;
      height: 14px;
      margin-top: -7px;
      background: #0084ff;
    }
  }
  .el-form-item {
    &.province {
      .el-form-item__content {
        div {
          margin-right: 10px;
        }
        .el-input {
          width: 200px;
        }
      }
    }
    &.up_poster {
      .el-form-item__content {
        display: flex;
        align-items: center;
        // .el-upload--text {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   width: 178px;
        //   height: 178px;
        // }
      }
    }
    .el-input {
      width: 400px;
    }
    .form_tips {
      color: #888;
      font-size: 12px;
    }
  }
  .color_E6A23C {
    color: #e6a23c;
  }
}
</style>
