var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ActivityAdd" },
    [
      _c("div", { staticClass: "tips" }, [_vm._v("基础信息")]),
      _c(
        "el-form",
        {
          ref: "baseForm",
          staticClass: "mt20",
          attrs: {
            rules: _vm.rules,
            model: _vm.baseInfo,
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动名称：", prop: "actName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入活动名称 ",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.baseInfo.actName,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "actName", $$v)
                  },
                  expression: "baseInfo.actName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间：", prop: "timeRange" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOptionsStart
                },
                model: {
                  value: _vm.baseInfo.timeRange,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "timeRange", $$v)
                  },
                  expression: "baseInfo.timeRange"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "province",
              attrs: { label: "活动区域：", prop: "areaList" }
            },
            [
              _c(
                "ul",
                { staticClass: "fee-ul" },
                _vm._l(_vm.baseInfo.areaList, function(item, ii) {
                  return _c(
                    "li",
                    {
                      key: ii,
                      staticClass: "f v",
                      staticStyle: { margin: "10px 0 0" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "f fvc" },
                        [
                          _c("v-distpicker", {
                            attrs: {
                              province: item.province,
                              city: item.city,
                              placeholders: { province: "全部", city: "全部" },
                              "hide-area": ""
                            },
                            on: {
                              province: function(data) {
                                return _vm.onChangeProvince(data, ii)
                              },
                              city: function(data) {
                                return _vm.onChangeCity(data, ii)
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-circle-close",
                            on: {
                              click: function($event) {
                                return _vm.delStoreFee(item, ii)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "form_tips" }, [
                _vm._v(" （不在活动区域 "),
                _c("span", { staticClass: "color_E6A23C" }, [_vm._v("[省市]")]),
                _vm._v(" 的用户，无法参与活动，请谨慎设置） ")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addStoreFee } },
                [_vm._v("添 加 活 动 区 域")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "up_poster",
              attrs: { label: "活动海报图：", prop: "poster" }
            },
            [
              _c(
                "label",
                {
                  staticClass: "el-upload el-upload--picture-card",
                  attrs: { for: "brand-logo" }
                },
                [
                  _c("input", {
                    ref: "brandlogo",
                    attrs: {
                      type: "file",
                      hidden: "",
                      id: "brand-logo",
                      "data-type": "brandLogo",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadImage }
                  }),
                  _vm.baseInfo.poster
                    ? _c("img", {
                        staticStyle: { width: "142px", height: "142px" },
                        attrs: { src: _vm.baseInfo.poster }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              ),
              _c("span", { staticClass: "ml10 form_tips" }, [
                _vm._v(
                  "建议尺寸750x876 px，支持JPG，PNG，JPEG，GIF格式，限制大小4M内"
                )
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "投诉电话：" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入投诉电话",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.baseInfo.complainMobile,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "complainMobile", $$v)
                  },
                  expression: "baseInfo.complainMobile"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播开始时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "开播时间",
                  "picker-options": _vm.pickerOptionsStart
                },
                model: {
                  value: _vm.baseInfo.liveStartTime,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "liveStartTime", $$v)
                  },
                  expression: "baseInfo.liveStartTime"
                }
              }),
              _c("div", { staticClass: "form_tips" }, [
                _vm._v(
                  "（到达直播开始时间后，活动页【预约直播】入口将变更为【观看直播】）"
                )
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直播地址：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写直播页URL" },
                model: {
                  value: _vm.baseInfo.liveUrl,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "liveUrl", $$v)
                  },
                  expression: "baseInfo.liveUrl"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitInfo } },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }